//--Справочники--
const dicts = {
    // categories: {
    //     path: "dicts/categories",
    //     parse: false,
    //     loading: true,
    //     data: []
    // },
}

export { dicts };